// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery");
import $ from 'jquery';

global.$ = $
global.jQuery = $

require('jquery-ui');


import 'bootstrap/dist/js/bootstrap'
import "bootstrap/dist/css/bootstrap";
import "stylesheets/public"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "@fortawesome/fontawesome-free/css/all.css";
import "chartkick/chart.js"
//require("trix")
//require("@rails/actiontext")

require("packs/custom")
//require("packs/init")
